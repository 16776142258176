import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "exampleIdHiddenField",
    "form",
    "searchingOverlay",
    "timer",
  ];

  connect() {
    this.formTarget.addEventListener("submit", this.handleSubmit);
    this.formTarget.addEventListener("turbo:submit-end", this.submitEnd);
  }

  disconnect() {
    this.formTarget.removeEventListener("submit", this.handleSubmit);
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  handleSubmit = (evt) => {
    this.searchingOverlayTarget.classList.remove("hidden");
    this.startTimer();
  };

  submitEnd = (evt) => {
    // when a turbo frame is rendered
    this.searchingOverlayTarget.classList.add("hidden");
  };

  startTimer() {
    this.startTime = Date.now();
    this.timerInterval = setInterval(() => {
      const elapsedTime = (Date.now() - this.startTime) / 1000;
      this.timerTarget.textContent = `${elapsedTime.toFixed(1)}s`;
    }, 100);
  }

  addExample(evt) {
    const { params } = evt;
    const { aiEntityId } = params;
    this.exampleIdHiddenFieldTargets.forEach((target) => {
      if (target.value === "") {
        target.value = aiEntityId;
        this.formTarget.requestSubmit();
      }
    });
  }

  removeExample(evt) {
    const { params } = evt;
    const { aiEntityId } = params;
    this.exampleIdHiddenFieldTargets.forEach((target) => {
      if (target.value === aiEntityId) {
        target.value = "";
        this.formTarget.requestSubmit();
      }
    });
  }
}
